












































































































































































































import { Component, Watch } from "vue-property-decorator";
import Hint from "@/views/AutomaticPaymentsOverview/Hint.vue";
import Table from "@/components/Table.vue";
import Actions from "@/components/Actions.vue";
import FileCsvButton from "@/components/Button/FileCsvButton.vue";
import PrintButton from "@/components/Button/PrintButton.vue";
import { namespace } from "vuex-class";
import FscCard from "@/components/Card/FscCard.vue";
import FilterAndSearch from "@/components/FilterAndSearch.vue";
import StudentCol from "@/views/AutomaticPaymentsOverview/StudentCol.vue";
import { LIVE_PAY } from "@/constants/PaymentWorkflow";
import TextDecimal from "@/components/TextDecimal.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import VerticalDivider from "@/components/VerticalDivider.vue";
import PaymentHeader from "@/views/AutomaticPaymentsOverview/PaymentHeader.vue";
import BooleanStatus from "@/components/BooleanStatus.vue";
import { mixins } from "vue-class-component";
import AutomaticPaymentMixin from "@/mixins/AutomaticPaymentMixin";
import _ from "lodash";
import FileCheckButton from "@/components/Button/FileCheckButton.vue";
import GenerateStudentInvoiceModal from "@/views/AutomaticPaymentsOverview/GenerateStudentInvoiceModal.vue";
import UnfinishedInvoiceModal from "@/views/AutomaticPaymentsOverview/UnfinishedInvoiceModal.vue";
import GenerateFinalInvoiceConfirmationModal from "@/views/AutomaticPaymentsOverview/GenerateFinalInvoiceConfirmationModal.vue";
import InvoiceDocumentPreview from "@/views/AutomaticPaymentsOverview/InvoiceDocumentPreview.vue";
import { LivePayPaymentStatusEnum } from "@/enums/LivePayPaymentStatusEnum";
import LivePayFilterModal from "@/components/Modal/LivePayFilterModal.vue";

const LivePayModule = namespace("live-pay");
const LivePayFilterModule = namespace("live-pay/localFilter");
const SaldoModule = namespace("saldo");

@Component({
  components: {
    InvoiceDocumentPreview,
    GenerateFinalInvoiceConfirmationModal,
    UnfinishedInvoiceModal,
    GenerateStudentInvoiceModal,
    FileCheckButton,
    BooleanStatus,
    PaymentHeader,
    VerticalDivider,
    FscSimpleCard,
    TextDecimal,
    StudentCol,
    FilterAndSearch,
    FscCard,
    PrintButton,
    FileCsvButton,
    Actions,
    Table,
    Hint,
    LivePayFilterModal,
  },
})
export default class LivePay extends mixins(AutomaticPaymentMixin) {
  public name = "LivePay";

  protected filtered = "";

  protected filterModal = "live-pay-filter-modal";

  protected modalId = "generate-student-modal-1";
  protected documentId = "document-preview-1";
  protected unfinishedEdModal = "unfinishedEdModal-1";
  protected generateFinalInvoiceConfirmationModalId = "live-pay-generate-final-invoice-confirmation-modal-id";

  protected studentEducationFields = [
    {
      key: "selected",
      label: "",
      class: "w-30",
    },
    {
      key: "student",
      label: "",
    },
    {
      key: "status",
      label: this.$t("general.status"),
    },
    {
      key: "licenseClass",
      label: this.$t("sidebar.training"),
    },
    {
      key: "payments",
      label: this.$t("automatic_payment.payments_made"),
    },
    {
      key: "actualBalance",
      label: this.$t("automatic_payment.actual_balance"),
    },
    {
      key: "previewBalance",
      label: this.$t("automatic_payment.preview_balance"),
    },
    {
      key: "practicalExamDate",
      label: this.$t("automatic_payment.practical_exam_date"),
    },
    {
      key: "finishedEducation",
      label: this.$t("automatic_payment.education_finished"),
    },
    {
      key: "finalInvoiceDate",
      label: this.$t("automatic_payment.date_of_final_invoice"),
    },
  ];

  @LivePayFilterModule.State("filter")
  public livePayLocalFilter: any;

  @LivePayFilterModule.Mutation("SET_LOCAL_FILTER")
  public setLivePayLocalFilter!: (payload: Record<any, any>) => void;

  @LivePayModule.Action("findAllStudentList")
  public paymentWorkflowsStudentListFindAll: any;

  @LivePayModule.Getter("getStudentList")
  public paymentWorkflowsStudentList: any;

  @LivePayModule.Getter("getStudentListTotal")
  public paymentWorkflowsStudentTotal: any;

  @LivePayModule.Getter("getStudentListLoading")
  public paymentWorkflowsStudentListLoading: any;

  @LivePayModule.Action("findOverviewInfo")
  public findOverviewInfo: any;

  @LivePayModule.Getter("getOverviewInfoLoading")
  public getOverviewInfoIsLoading: any;

  @LivePayModule.Getter("getOverviewInfo")
  public getOverviewInfo: any;

  @SaldoModule.Action("findOne")
  public findAllPaymentWorkflowBalance: any;

  @LivePayModule.Action("findOne")
  public livePayPaymentToggle: any;

  @LivePayModule.Getter("getDataItem")
  public toggleResponse: any;

  public livePayToggled = false;

  public requestQuery(query: Record<any, any> | null): void {
    this.query = query;
    this.paymentWorkflowsStudentListFindAll({
      ...query,
      ...this.paymentFilter,
      studentName: query?.searchTerm,
      paymentWorkflowId: LIVE_PAY,
    });
  }

  protected onChangeFilter(): void {
    (this.$refs.educationTable as Table).onSearch(this.filtered);
  }

  public async mounted(): Promise<void> {
    this.cachedFilter();
    await this.findOverviewInfo(LIVE_PAY);
    await this.findAllPaymentWorkflowBalance({
      id: LIVE_PAY,
      resource: "balance/payment-workflow",
    });
    this.$root.$on("before-destroyed-component", (props: any) => {
      if (_.isBoolean(props.success) && props.success && props.component == this.name) {
        this.requestQuery(this.query);
      }
    });
  }

  protected get livePay(): number {
    return LIVE_PAY;
  }

  public async onPauseLivePay(livePay: any) {
    await this.livePayPaymentToggle({
      id: livePay ? livePay.studentEducationId : null,
      resource: "live-pay/toggle-live-pay",
    });
    this.livePayToggled = true;
  }

  @Watch("livePayToggled", { deep: true, immediate: false })
  public async onLivePayToggleChange(toggle: any) {
    await this.findOverviewInfo(LIVE_PAY);
    await this.findAllPaymentWorkflowBalance({
      id: LIVE_PAY,
      resource: "balance/payment-workflow",
    });
    this.requestQuery(this.query);
    this.livePayToggled = false;
  }

  private getColor(statusId: LivePayPaymentStatusEnum) {
    switch (statusId) {
      case LivePayPaymentStatusEnum.RED:
        return "#dc0c23";
      case LivePayPaymentStatusEnum.YELLOW:
        return "#F9B342";
      case LivePayPaymentStatusEnum.GREEN:
        return "#17a533";
      default:
        return "#ffffff";
    }
  }

  protected createFinalInvoiceConfirmed() {
    this.$bvModal.hide(this.modalId);
  }

  private onFilterHandle() {
    this.setLivePayLocalFilter({
      filter: this.paymentFilter,
      active: this.appliedFilter,
    });
    this.onFilter();
  }

  private cachedFilter() {
    if (this.livePayLocalFilter instanceof Object) {
      const { filter, active } = this.livePayLocalFilter;
      this.paymentFilter = filter;
      this.appliedFilter = active;
    }
  }
}
